import { useEffect, useState } from "react";
import { AnalyticsApi } from "../../../../controller/analytics-api";
import { Result } from "../../../hooks/result";

export function useUsers(
    enabled: boolean,
): Result<readonly AnalyticsApi.User[]> {
    const [users, setUsers] = useState<Result<readonly AnalyticsApi.User[]>>(
        {},
    );

    useEffect(() => {
        if (!enabled) {
            setUsers({ data: [] });
            return () => {
                // Do nothing.
            };
        }
        setUsers({});

        let unmount = false;
        AnalyticsApi.listUsers()
            .then((data) => {
                if (unmount) {
                    return;
                }
                setUsers({ data });
            })
            .catch((error) => {
                if (unmount) {
                    return;
                }
                setUsers({ error });
            });

        return () => {
            unmount = true;
        };
    }, [enabled]);

    return users;
}
